import { extendTheme, type ThemeConfig } from '@chakra-ui/react'

const colors = {
  purple: {
    50: '#F2EEFB',
    100: '#D8CDF4',
    200: '#B29AEA',
    300: '#7E57DB',
    400: '#5327B9',
    500: '#3C1D87',
    600: '#261254',
    700: '#170B33',
    800: '#0F0722',
    900: '#080411',
  },
}

const fonts = {
  heading: `bouldextralight, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
  body: `bouldregular, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
  mono: `silkamonoregular, SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace`,
}

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
}

const activeLabelStyles = {
  transform: 'scale(0.85) translateY(-24px)',
}

const floatingInputLabel = {
  components: {
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles,
              },
            },
            'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label':
              {
                ...activeLabelStyles,
              },
            label: {
              top: 0,
              left: 0,
              zIndex: 2,
              position: 'absolute',
              backgroundColor: 'white',
              pointerEvents: 'none',
              mx: 3,
              px: 3,
              my: 2,
              transformOrigin: 'left top',
            },
          },
        },
      },
    },
  },
}

const theme = extendTheme({ colors, fonts, config, ...floatingInputLabel })

export default theme
