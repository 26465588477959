import {
  Flex,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  //useBreakpointValue,
} from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import PfNormRadio from '../../elements/pfNormRadio'
import SortIcon from '../../elements/sortIcon'
import PfTableRow from './pfTableRow'
import useMultiSort from './pfTableSort'

export type BuildingProps = {
  name: string
  slug: string
  type: string
  floorArea: number
  epcRating: string
  thumbnail: string
  tlData: {
    type: string
    value: number
    target: number
    units: string
  }[]
}

const loadingBuilding = {
  name: 'Building the First',
  slug: 'building-the-first',
  type: 'distrib',
  floorArea: 24302,
  epcRating: 'A+',
  thumbnail: 'https://avatars.dicebear.com/api/bottts/building.svg',
  tlData: [
    { type: 'energy', value: 456100000, target: 300000000, units: 'Wh' },
    { type: 'carbon', value: 78600, target: 78600, units: 'kg' },
    { type: 'water', value: 1993, target: 4000, units: 'm' },
  ],
}

type PfTableProps = {
  loading: boolean
  buildings: BuildingProps[]
}

const PfTable = ({ loading, buildings }: PfTableProps) => {
  const { t, i18n } = useTranslation('portfolio')
  const [normalisation, setNormalisation] = useState<string>('total')
  const { state, dispatch, sortFunction } = useMultiSort()

  // note, this copies the array, which is useful, because the sort
  // is in place
  const tableBuildings: BuildingProps[] = loading
    ? Array(7)
        .fill(loadingBuilding)
        .map((placeholder, idx) => ({
          ...placeholder,
          slug: `${placeholder.slug}-idx${idx}`,
        }))
    : buildings
        .map((building) => {
          return {
            ...building,
            floorArea:
              normalisation === 'sqft'
                ? building.floorArea * 10.7639
                : building.floorArea,
            type: t(`buildingType.${building.type}`),
            tlData: building.tlData.map((datum) => {
              const normDenom =
                normalisation === 'total'
                  ? 1
                  : normalisation === 'sqm'
                  ? building.floorArea
                  : building.floorArea * 10.7639

              return {
                type: datum.type,
                value: datum.value / normDenom,
                target: datum.target / normDenom,
                units: datum.units,
              }
            }),
          }
        })
        .sort(sortFunction)
  //tableBuildings.sort(sortFunction)

  // TODO: Table columns should collapse on smaller screens to make the
  // table display nicely, leave this here for now as it is helpful for
  // that (need to omit columns rather than use responsive visible params)
  //const breakpoint = useBreakpointValue({
  //  base: 'base',
  //  sm: 'sm',
  //  md: 'md',
  //  lg: 'lg',
  //  xl: 'xl',
  //  '2xl': '2xl',
  //})

  const locale = i18n.resolvedLanguage

  return (
    <TableContainer>
      <Table size={['sm', 'sm', 'sm', 'sm', 'md']}>
        <Thead>
          <Tr>
            <Th w="80px" rowSpan={2}>
              {/*breakpoint*/}
            </Th>
            <Th rowSpan={2}>{t('siteName').toLocaleUpperCase(locale)}</Th>
            <Th rowSpan={2}>{t('type').toLocaleUpperCase(locale)}</Th>
            <Th w="10%" rowSpan={2}>
              {t('floorArea').toLocaleUpperCase(locale)}
            </Th>
            <Th w="10%" rowSpan={2}>
              {t('epcRating').toLocaleUpperCase(locale)}
            </Th>
            <Th w="30%" colSpan={3} pl={2} pr={2}>
              <Flex>
                {t('consumption').toLocaleUpperCase(locale)}
                <Text pl={1}>/MONTH</Text>
                <Spacer />
                <PfNormRadio
                  value={normalisation}
                  onChange={setNormalisation}
                />
              </Flex>
            </Th>
          </Tr>
          <Tr>
            <Th w="10%">
              <Flex>
                {t('energy').toLocaleUpperCase()}
                <Spacer />
                <SortIcon
                  sort={state.energy}
                  setSort={(sort, sortKey) => {
                    dispatch({ type: sort, payload: sortKey })
                  }}
                  sortKey={'energy'}
                />
              </Flex>
            </Th>
            <Th w="10%">
              <Flex>
                {t('carbon').toLocaleUpperCase()}
                <Spacer />
                <SortIcon
                  sort={state.carbon}
                  setSort={(sort, sortKey) => {
                    dispatch({ type: sort, payload: sortKey })
                  }}
                  sortKey={'carbon'}
                />
              </Flex>
            </Th>
            <Th w="10%">
              <Flex>
                {t('water').toLocaleUpperCase()}
                <Spacer />
                <SortIcon
                  sort={state.water}
                  setSort={(sort, sortKey) => {
                    dispatch({ type: sort, payload: sortKey })
                  }}
                  sortKey={'water'}
                />
              </Flex>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {tableBuildings.map((building) => (
            <PfTableRow
              locale={locale}
              {...building}
              key={building.slug}
              loading={loading}
            />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

export default PfTable
