import { useParams } from 'react-router-dom'

const Dashboard = () => {
  const params = useParams()

  const buildingSlug = params['buildingSlug'] ?? 'unknown building'

  return <p>dashboard for {`${buildingSlug}`}</p>
}

export default Dashboard
