import { Box, Center, Flex, Skeleton, Spacer } from '@chakra-ui/react'
import displayScale from '../../utils/unitHelpers/displayScale'

type trafficLightBlockProps = {
  type: string
  value: number
  target: number
  units: string
  loading: boolean
}

const PfTrafficLightBlock = ({
  type,
  value,
  target,
  units,
  loading,
}: trafficLightBlockProps) => {
  const percentage = Math.round((value / target) * 100)

  const greenColor = loading
    ? '#91c138'
    : percentage < 95
    ? '#91c138'
    : 'gray.400'
  const amberColor = loading
    ? '#f9c138'
    : percentage >= 95 && percentage <= 115
    ? '#f9c138'
    : 'gray.400'
  const redColor = loading
    ? '#f3243e'
    : percentage > 115
    ? '#f3242e'
    : 'gray.400'

  const { valueString, unit: formatUnits } = displayScale(value, 1, units)

  const finalUnits =
    type === 'energy'
      ? formatUnits
      : type === 'carbon'
      ? `${formatUnits}CO<sub>2</sub>`
      : formatUnits === 'l'
      ? formatUnits
      : `${formatUnits}<sup>3<sup>`

  //console.log(`${type} ${units} ${formatUnits} ${finalUnits}`)

  return (
    <Box w="100%">
      <Center>
        <Skeleton isLoaded={!loading}>
          {valueString}
          <span dangerouslySetInnerHTML={{ __html: finalUnits }}></span>
        </Skeleton>
      </Center>

      <Center>
        <Flex mt={'.5em'} w="70%" alignSelf={'center'}>
          <Box w="1em" h="1em" borderRadius={'1em'} bgColor={greenColor} />
          <Spacer />
          <Box w="1em" h="1em" borderRadius={'1em'} bgColor={amberColor} />
          <Spacer />
          <Box w="1em" h="1em" borderRadius={'1em'} bgColor={redColor} />
        </Flex>
      </Center>
    </Box>
  )
}

export default PfTrafficLightBlock
