import {
  AspectRatio,
  Center,
  ComponentWithAs,
  Flex,
  HStack,
  IconProps,
  Skeleton,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { tightMapCalc } from '../../utils/colorMaps/tightMap'

type pfHeadlineItemProps = {
  Icon: ComponentWithAs<'svg', IconProps>
  label: string
  value: number
  target: number
  units: string
  description: string
  upIsBetter: boolean
  loading: boolean
}

const PfHeadlineItem = ({
  Icon,
  label,
  value,
  target,
  units,
  description,
  upIsBetter,
  loading,
}: pfHeadlineItemProps) => {
  const { t } = useTranslation(['portfolio'])
  const percentage = loading ? 100 : Math.round((value / target) * 100)
  const { color: iconColor } = tightMapCalc({
    value: percentage,
    range: 150,
    upIsBetter,
  })
  return (
    <AspectRatio
      ratio={1}
      backgroundColor={'white'}
      w={'12em'}
      shadow="md"
      textColor={'purple.500'}
    >
      <VStack
        divider={
          <StackDivider borderColor={iconColor} w="70%" alignSelf="center" />
        }
      >
        <VStack>
          <Skeleton isLoaded={!loading}>
            <Icon w={10} h={10} color={iconColor} />
          </Skeleton>
          <Skeleton isLoaded={!loading}>
            <p>{t(label).toLocaleUpperCase()}</p>
          </Skeleton>
        </VStack>
        <VStack maxW="85%">
          <Skeleton isLoaded={!loading} as={HStack}>
            <Flex alignItems={'baseline'}>
              <Text fontSize="2xl">{value}</Text>
              <Text dangerouslySetInnerHTML={{ __html: units }}></Text>
            </Flex>
          </Skeleton>
          <Center maxW="100%">
            <Skeleton isLoaded={!loading}>
              <Text textAlign={'center'}>
                {t(description).toLocaleUpperCase()}
              </Text>
            </Skeleton>
          </Center>
          <Center>
            <Skeleton isLoaded={!loading}>
              <Text textAlign={'center'} fontSize="sm">
                {t('thisMonth').toLocaleUpperCase()}
              </Text>
            </Skeleton>
          </Center>
        </VStack>
      </VStack>
    </AspectRatio>
  )
}

export default PfHeadlineItem
