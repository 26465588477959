import { useParams } from 'react-router-dom'

const Building = () => {
  const params = useParams()

  const buildingSlug = params['buildingSlug'] ?? 'unknown builidng'

  return <p>building page for {`${buildingSlug}`}</p>
}

export default Building
