import { useReducer } from 'react'
import { BuildingProps } from './pfTable'

type ReducerState = {
  energy: string
  carbon: string
  water: string
  sortType: string
  sortKey: string
}

type ReducerAction = {
  type: string
  payload: string
}

const initialState: ReducerState = {
  energy: 'none',
  carbon: 'none',
  water: 'none',
  sortType: 'none',
  sortKey: 'none',
}

const reducer = (_state: ReducerState, action: ReducerAction): ReducerState => {
  const { type, payload } = action
  switch (type) {
    case 'asc':
      return {
        energy: payload === 'energy' ? 'asc' : 'none',
        carbon: payload === 'carbon' ? 'asc' : 'none',
        water: payload === 'water' ? 'asc' : 'none',
        sortType: 'asc',
        sortKey: payload,
      }
    case 'dsc':
      return {
        energy: payload === 'energy' ? 'dsc' : 'none',
        carbon: payload === 'carbon' ? 'dsc' : 'none',
        water: payload === 'water' ? 'dsc' : 'none',
        sortType: 'dsc',
        sortKey: payload,
      }
    case 'none':
    default:
      return {
        energy: 'none',
        carbon: 'none',
        water: 'none',
        sortType: 'none',
        sortKey: 'none',
      }
  }
}

const useMultiSort = () => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const sortFunction = (a: BuildingProps, b: BuildingProps): number => {
    if (state.sortType === 'none') {
      return 0
    }
    const valueA =
      a.tlData.find((datum) => datum.type === state.sortKey)?.value ?? 0
    const valueB =
      b.tlData.find((datum) => datum.type === state.sortKey)?.value ?? 0

    if (state.sortType === 'asc') {
      return valueA >= valueB ? 1 : -1
    } else if (state.sortType === 'dsc') {
      return valueA >= valueB ? -1 : 1
    } else {
      // Should have caught this case above for the sake of completeness...
      return 0
    }
  }
  return {
    state,
    dispatch,
    sortFunction,
  }
}

export default useMultiSort
