import {
  Skeleton,
  Td,
  Tr,
  Image,
  // Link
} from '@chakra-ui/react'
//import { Link as RouterLink } from 'react-router-dom'

import PfTrafficLightBlock from '../../elements/pfTrafficLightBlock'

type BuildingRowProps = {
  locale: string
  name: string
  slug: string
  type: string
  floorArea: number
  epcRating: string
  thumbnail: string
  tlData: {
    type: string
    value: number
    target: number
    units: string
  }[]
  loading: boolean
}

const PfTableRow = ({
  locale,
  name,
  slug,
  type,
  floorArea,
  epcRating,
  thumbnail,
  tlData,
  loading,
}: BuildingRowProps) => {
  return (
    <Tr>
      <Td p={[0, 0]} position="relative">
        <a href={`https://live.opti-twin.co.uk/building/${slug}/dashboard`}>
          <Image
            src={thumbnail}
            alt="a picutre of the builidng"
            boxSize="75px"
          />
        </a>
      </Td>
      <Td>
        <Skeleton isLoaded={!loading}>
          <a href={`https://live.opti-twin.co.uk/building/${slug}/dashboard`}>
            {name}
          </a>
        </Skeleton>
      </Td>
      <Td>
        <Skeleton isLoaded={!loading}>
          {type.toLocaleUpperCase(locale)}
        </Skeleton>
      </Td>
      <Td>
        <Skeleton isLoaded={!loading}>
          {floorArea.toLocaleString(locale, { maximumSignificantDigits: 5 })}
        </Skeleton>
      </Td>
      <Td>
        <Skeleton isLoaded={!loading}>{epcRating}</Skeleton>
      </Td>
      {tlData.slice(0, 3).map((tlDatum) => (
        <Td p={0.75} key={tlDatum.type}>
          {PfTrafficLightBlock({
            type: tlDatum.type,
            value: tlDatum.value,
            target: tlDatum.target,
            units: tlDatum.units,
            loading,
          })}
        </Td>
      ))}
    </Tr>
  )
}

export default PfTableRow
