import { Box, Container, Link, Stack, Text } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Footer = () => {
  const { t } = useTranslation('nav')
  return (
    <Box
      as="footer"
      bgGradient="linear(to-r, purple.800, purple.300, purple.800)"
      color="gray.200"
    >
      <Container
        as={Stack}
        maxW="4xl"
        direction={{ base: 'column', md: 'row' }}
        spacing={4}
        py={4}
        justify={{ base: 'center', md: 'space-between' }}
        align="center"
        position="relative"
      >
        <Stack direction="row" spacing={6}>
          {/* <Link as={RouterLink} to={'/contact'}>
            {t('contactUs')}
          </Link>
          <Link as={RouterLink} to={'/support'}>
            {t('techSupport')}
          </Link>
          <Link as={RouterLink} to={'/terms'}>
            {t('terms')}
          </Link>
          <Link as={RouterLink} to={'/privacy'}>
            {t('privacyPolicy')}
          </Link> */}
        </Stack>
        <Text>© 2022 Opti-Twin Limited</Text>
      </Container>
    </Box>
  )
}

export default Footer
