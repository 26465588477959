const prefixLookup: { [key: number]: string } = {
  '-24': 'y',
  '-21': 'z',
  '-18': 'a',
  '-15': 'f',
  '-12': 'p',
  '-9': 'n',
  '-6': 'μ',
  '-3': 'm',
  0: '',
  3: 'k',
  6: 'M',
  9: 'G',
  12: 'T',
  15: 'P',
  18: 'E',
  21: 'Z',
  24: 'Y',
}

//const volumeLookup: { [key: number]: string} = {
//  '-6': 'ml',
//  '-3': 'l',
//  0: 'm'
//}

const massLookup: { [key: number]: string } = {
  '-9': 'μg',
  '-6': 'mg',
  '-3': 'g',
  0: 'kg',
  3: 't',
  6: 'kt',
  9: 'Mt',
  12: 'Gt',
}

function displayScale(
  value: number,
  precision: number,
  unit: string,
): { value: number; valueString: string; unit: string } {
  // Special case for volume, needs improving:
  if (unit === 'm3') {
    if (value > 100) {
      return {
        value,
        valueString: value.toLocaleString(), //toPrecision(precision),
        unit: 'm',
      }
    } else {
      return {
        value: value * 1000,
        valueString: (value * 1000).toLocaleString(), // toPrecision(precision),
        unit: 'l',
      }
    }
  }

  const expStr = value.toExponential(precision + 1)
  const idx = expStr.indexOf('e')

  if (idx <= 0) {
    // Wasn't a real number
    return {
      value: NaN,
      valueString: 'NaN',
      unit: 'NOT A UNIT',
    }
  }

  const mantissa = +expStr.slice(0, idx)
  const exponent = +expStr.slice(idx + 1)

  const shift =
    exponent >= 0 ? Math.abs(exponent) % 3 : 3 - (Math.abs(exponent) % 3)

  const siMantissa = mantissa * 10 ** shift
  const siExponent = exponent - shift

  const siPrefix = prefixLookup[siExponent] ?? 'Prefix not found'
  const massUnit = massLookup[siExponent] ?? 'Unit not found'

  const finalUnit = unit === 'kg' ? massUnit : `${siPrefix}${unit}`

  const stringPrecision = shift === 0 ? precision + 1 : precision + 2

  return {
    value: siMantissa,
    valueString: siMantissa.toPrecision(stringPrecision),
    unit: finalUnit,
  }
}

export default displayScale
